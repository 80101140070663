<template>
    <div class="panel">
        <h2>Introduction</h2>
        <div class="p">
            An Ethereum wallet is an application that allows you to manage your account. Similar to your physical
            wallet, it contains everything needed to verify your identity and handle your assets. With a wallet, you can
            log into applications, check balances, send transactions, and authenticate your identity.<br /><br />

            An Ethereum account consists of a pair of keys. One key generates an address that you can freely share,
            while the other key must remain private, as it is used for signing transactions. By using these two keys
            together, you can hold assets and execute transactions securely.<br />
        </div>
        <div class="shortcut">
            <button type="button" class="button-large" @click="scrollDown">Start now</button>
        </div>

        <h2>Security</h2>
        <p>
            Based on the ETHWallet wallet generation algorithm, wallet addresses can be generated using AI computation
            according to custom prefix and suffix information. You can freely set the desired prefix or suffix for your
            wallet address (note: we recommend a maximum of 4 characters; longer inputs will significantly increase
            computation time).<br /><br />

            We utilize dual encryption technology and support offline generation to ensure the utmost security of your
            Ethereum wallet private key. For optimal safety, it is recommended to select offline mode during the
            generation process. This allows you to create your exclusive ETHWallet wallet securely and confidently.<br />
        </p>

        <h2>Scenarios</h2>
        <p>
            With ETHWallet, you can create a unique identity wallet by setting a custom prefix like "aaaa" and a suffix
            like "8888" for your wallet address. This makes your wallet stand out during transactions, much like having
            a phone number ending in "88888" that draws admiration and attention.<br /><br />

            For projects like "999.com," ETHWallet allows you to generate wallet addresses with a customized suffix such
            as "999." This creates a wallet uniquely associated with your project, making it perfect for purposes like
            receiving payments, holding funds, or showcasing project treasury pools.<br />
        </p>

        <h2>Examples for Illustration</h2>
        <div class="p">
            Ethereum ETHWallet addresses are composed of a combination of letters and numbers. You can customize the
            position of the prefix and suffix in the wallet address using letters or numbers as desired.<br /><br />

            The computation time required to generate the address depends on the length of the custom suffix or prefix
            you select. For optimal efficiency, it is recommended to limit the customization to 4 to 5 characters,
            ensuring a quicker generation process without compromising the uniqueness of your wallet.<br />
            Examples:
            <ul>
                <li><span class="monospace">0xab5801a7d398351b8be11c439e05c5b3259aec9b</span></li>
                <li><span class="monospace">0xaaaa01a7d398351b8be11c439e05c5b3259a8888</span></li>
            </ul>
        </div>

        <P>
            Using the ETHWallet AI algorithm, a wallet address with the prefix "aaaa" and the suffix "8888" can be
            generated, making it exclusively yours.<br /><br />

            To ensure maximum security, it is recommended to perform the key generation and handling process offline.
            Once generated, you can safely copy the private key and import it into your mobile wallet application. This
            allows you to activate and start using your Ethereum wallet with the custom address featuring the "aaaa"
            prefix and "8888" suffix.<br /><br />

            This approach combines the uniqueness of a personalized wallet address with the robust security of offline
            operations, ensuring your assets and identity remain protected.<br />
        </P>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                scrollTimeOut: null,
            };
        },
        methods: {
            scrollDown() {
                this.scrollTo(document.getElementById('input-panel'), -1);
            },
            scrollTo(element, lastValue) {
                let currentValue = window.scrollY;
                let diff = element.getBoundingClientRect().top / 6;
                if (Math.abs(diff) > 1 && currentValue > lastValue) {
                    window.scrollTo(0, window.scrollY + diff);
                    this.scrollTimeOut = setTimeout(this.scrollTo, 30, element, currentValue);
                } else if (currentValue >= lastValue) {
                    document.getElementById('input').focus();
                }
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "../css/variables"
    p, .p
        margin: 15px 0 20px
        color: $text-alt
        overflow-x: hidden
        text-overflow: ellipsis
        .monospace
            font-family: $monospace-font
            font-size: 0.85em
    .shortcut
        text-align: center
        .button-large
            width: 150px
            margin: 15px 0 35px
</style>
